var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',[_c('h1',[_vm._v("Livestreams")])]),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"mb-5 add-button",on:{"click":function($event){return _vm.addLivestream()}}},[_vm._v(" Add ")])],1)],1),_c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('vuetable',{ref:"livestreamtable",attrs:{"api-mode":false,"fields":[
          'active',
          'id',
          'title.nl_NL',
          'type.nl_NL',
          'lang',
          'actions'
        ],"data":_vm.livestreams},scopedSlots:_vm._u([{key:"active",fn:function(props){return [_c('b-form-checkbox',{staticClass:"content-switch",attrs:{"name":"active","disabled":""},model:{value:(_vm.livestreams[props.rowIndex].active),callback:function ($$v) {_vm.$set(_vm.livestreams[props.rowIndex], "active", $$v)},expression:"livestreams[props.rowIndex].active"}})]}},{key:"actions",fn:function(props){return [_c('font-awesome-icon',{ref:"btnEdit",staticClass:"action-icon",attrs:{"icon":"edit","data-toggle":"tooltip","data-placement":"top","title":"Edit item"},on:{"click":function($event){return _vm.editLivestream(props.rowIndex)}}}),_c('font-awesome-icon',{staticClass:"ml-2 action-icon",attrs:{"icon":"trash","data-toggle":"tooltip","data-placement":"top","title":"Remove item"},on:{"click":function($event){return _vm.deleteLivestream(props.rowData, props.rowIndex)}}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }